.ant-layout-sider,
.ant-menu-dark,
.drawer .drawer-content,
.drawer-handle {
  background: #0a241e !important;
}

.ant-layout-sider-trigger {
  background: #0e342b;
}

/* fixes issue when clicking arrow in selects that also respond to focus changes */
.ant-select-arrow {
  pointer-events: none;
}

.content-full-width {
  max-width: inherit;
}

.leaflet-container {
  height: 240px;
  width: 100%;
}
